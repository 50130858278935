<template>
  <Layout navbarTransparent>
    <Header :image="current.procat_image" :header="current.procat_desc" :header_eng="current.procat_desc_en"
    />

    <!-- <div class="my-4">
    </div> -->

    <template v-for="(data,idx) in list">
      <div :key="data.pl1_id+idx">
        <comp-1 :data="data" :index="idx+1" />
      </div>
    </template>


  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import Header from './header.vue'
import Comp1 from './components/layout1-comp1.vue'

import store from "@/store";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTabs,
  BTab,
  BEmbed,
} from "bootstrap-vue";

export default {
  components: {
    Header, Comp1,

    Layout,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTabs,
    BTab,
    BEmbed,
  }, 
  mounted(){
      this.getNextDatas()
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  methods: {
    async getNextDatas(){
      this.current = await store.dispatch("product_categories/getDataList", {
        id: this.$route.params.id,
      });
      this.list = await store.dispatch("product_categories/getDataLayout1", {
        id: this.$route.params.id,
        langcode : this.currentLocale
      });
    },
  },
  data() {
    return {
      current: {}, list: []
    };
  },
};
</script>

<style>
.nav-tabs .nav-link.active {
  color: #ef6d22;
}
.nav-tabs .nav-link.active::after {
  background: #ef6d22 !important;
  height: 5px;
  border-radius: 3px;
}
</style>
