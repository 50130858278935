<template>
  <div class="py-5" :style="`background: ${ index % 2 != 0 ? '#fff' : '#e8f1f8'}`" @mouseenter="navVisible = true" @mouseleave="navVisible = false">
    <div class="container">
      <div v-html="data.pl1_header" class="mb-4"></div>
      <div class="position-relative" style="z-index:0">
        <div class="px-5">
          <swiper v-if="data.childrens" :id="index+'SECTION'" class="swiper-multiple" :options="swiperOptions">
            <swiper-slide cas v-for="(item, index) in data.childrens" :key="index+item.item_pl1c_id">
              <div class="card shadow" style="height:200px;overflow-y:auto">
                <div class="card-body" v-html="item.pl1c_content"></div>
              </div>
            </swiper-slide> 
          </swiper>
        </div>
        <div class="navigation" :style="`margin-top:0;margin-top:-4em;opacity:${navVisible ? 1 : 0}`">
          <div slot="button-next" class="swiper-button-next swiper-next" :id="`swiper-next${index}`">
            <div class="rounded-circle d-flex text-white" style="width:50px;height:50px;background:#0F6BB4">
              <i class="bx bx-chevron-right m-auto" style="font-size:50px"></i>
            </div>
          </div>
          <div slot="button-prev" class="swiper-button-prev swiper-prev" :id="`swiper-prev${index}`">
            <div class="rounded-circle d-flex text-white" style="width:50px;height:50px;background:#0F6BB4">
              <i class="bx bx-chevron-left m-auto" style="font-size:50px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      navVisible : false,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: "#swiper-next"+this.index,
          prevEl: "#swiper-prev"+this.index,
        },
        watchOverflow: true,
        breakpoints: {
          1600: {
            slidesPerView: 3,
            spaceBetween: 40,
          }, 
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {},
};
</script>


<style>


.swiper-button-next::after {
  content: 'asdqsd';
}
.swiper-button-prev::after {
  content: 'asdqsd';
}
</style>